// Override Bootstrap's Sass default variables
//
// Nearly all variables in Bootstrap are written with the `!default` flag.
// This allows you to override the default values of those variables before
// you import Bootstrap's source Sass files.
//
// Overriding the default variable values is the best way to customize your
// CSS without writing _new_ styles. For example, change you can either change
// `$body-color` or write more CSS that override's Bootstrap's CSS like so:
// `body { color: red; }`.


//
// Bring in Bootstrap
//

// Option 1
//
// Import all of Bootstrap's CSS

@import "bootstrap/scss/bootstrap";

// Option 2
//
// Place variable overrides first, then import just the styles you need. Note that some stylesheets are required no matter what.

// Toggle global options
$enable-gradients: true;
$enable-shadows: true;

$offcanvas-box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);

// Customize some defaults
$body-color: #333;
$body-bg: #fff;
$border-radius: .4rem;
$success: #7952b3;

// Required
// @import "bootstrap/scss/functions";
// @import "bootstrap/scss/variables";
// @import "bootstrap/scss/mixins";
// @import "bootstrap/scss/utilities";
// @import "bootstrap/scss/root";
// @import "bootstrap/scss/reboot";

// @import "bootstrap/scss/type";
// // @import "bootstrap/scss/images";
// @import "bootstrap/scss/containers";
// @import "bootstrap/scss/grid";
// // @import "bootstrap/scss/tables";
// // @import "bootstrap/scss/forms";
// @import "bootstrap/scss/buttons";
// @import "bootstrap/scss/transitions";
// @import "bootstrap/scss/dropdown";
// // @import "bootstrap/scss/button-group";
// // @import "bootstrap/scss/nav";
// // @import "bootstrap/scss/navbar"; // Requires nav
// // @import "bootstrap/scss/card";
// // @import "bootstrap/scss/breadcrumb";
// // @import "bootstrap/scss/accordion";
// // @import "bootstrap/scss/pagination";
// // @import "bootstrap/scss/badge";
// // @import "bootstrap/scss/alert";
// // @import "bootstrap/scss/progress";
// // @import "bootstrap/scss/list-group";
// @import "bootstrap/scss/close";
// // @import "bootstrap/scss/toasts";
// @import "bootstrap/scss/modal"; // Requires transitions
// // @import "bootstrap/scss/tooltip";
// // @import "bootstrap/scss/popover";
// // @import "bootstrap/scss/carousel";
// // @import "bootstrap/scss/spinners";
// @import "bootstrap/scss/offcanvas"; // Requires transitions
// // @import "bootstrap/scss/placeholders";

// // Helpers
// // @import "helpers";

// // Utilities
// @import "bootstrap/scss/utilities/api";


//
// Custom styles
//

@import "icon-list";

// body {
//   padding: 1.5rem;
// }

// Style Bootstrap icons
$defBgColor: #00349D;
$infoColor: #666666;
.carousel-indicators [data-bs-target] {
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
%title {
  font-size: 32px;
  font-weight: 600;
}
.bi {
  fill: currentColor;
}
.ovtherHeader {
  height: 150px;
  display: flex;
      align-items: center;
      .col-md-9 {
        display: flex;
      }
  .logo {
    margin-right: 160px;

    img {
      width: 100px;
      margin-bottom: 5px;
    }
    div{
      font-size: 14px;
      font-weight: bold;
    }
  }
  .list {
    font-size: 14px;
    display: flex;
    align-items: center;
    .item {
      font-size: 14px;
      color: #666;
    }
    a {
      background: #00349d;
          color: #fff;
          text-decoration: none;
          font-size: 14px;
          display: flex;
          align-items: center;
          border-radius: 5px;
          width: 90px;
          justify-content: center;
          height: 35px;
    }
  }
  .select_cont {
         li {
           text-align: center;
           color: #666;
           padding: 10px;
           &:hover {
             cursor:pointer
           }
         }
       }
  .bi-list {
         font-size: 30px;
         display: none;
         position: absolute;
         right: 20px;
       }
}
.fotter {
  background: #2D3237;
  padding: 60px 0;
  overflow: hidden;
  .col-md-9 {
     display: flex;
     justify-content: space-between;
         flex-wrap: wrap;
     .logo {
       margin-bottom: 40px;
       img {
         width: 100px;
         margin-bottom: 5px;
       }
       p {
         color: #D8D8D9;
         font-size: 12px;
       }
     }
     .link {
       color: #D8D8D9;
       display: flex;
       div:first-child {
         margin-right: 30px;
       }
       a {
         width: 160px;
         border-bottom: 1px solid #43474B;
         padding-bottom: 10px;
         font-size: 14px;
         display: block;
         text-decoration: none;
         padding-top: 10px;
         color: #D8D8D9;
         &.cur {
           color: #5A5B5C;
         }
       }
     }
     .help {
       color: #D8D8D9;
      font-size: 14px;
      p {
        display: flex;
         align-items: center;
        i {
              font-size: 24px;
              margin-right: 5px;
        }
        span {
          font-weight: bold;
          color: #fff;
        }
      }
     }
     .help1 {
       color: #FEFEFE;
       font-size: 14px;

       p {
         font-weight: bold;
         display: flex;
         align-items: center;
         i {
           font-size: 24px;
               font-weight: 100;
               margin-right: 5px;
         }
       }
       .btn {
          width: 100px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          background: $defBgColor;
          font-size: 12px;
          border-radius: 20px;
       }
     }
     .info {
       width: 100%;
       color: #d8d8d9;
       font-size: 12px;
       border-top: 1px solid #43474B;
       text-align: center;
       padding-top: 10px;
       margin-top: 40px;
       text-align: center;
       i{
        font-size: 12px;
       }
     }
  }

}

@media (min-width: 768px) {
  .col-md-9 {
        flex: 0 0 auto;
        width: 1200px;
    }
}
@media (max-width: 1200px) {
  .col-md-9 {
        flex: 0 0 auto;
        width: 75%;
    }
    .ovtherHeader {
      .list {
        display: none;
      }
      .bi-list {
        display: block;
      }
    }
}
@media (max-width: 768px) {
  .col-md-9 {
        flex: 0 0 auto;
        width: 100%;
    }
    %title {
      font-size: 28px;
      font-weight: 600;
    }
    .ovtherHeader {
      height: 60px;
      .logo {
        img {
          width: 60px;
        }
        div {
          font-size: 12px;
          margin-top: 0;
        }
      }
    }
}
